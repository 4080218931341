<template>
  <base-card dark>
    <v-img
      :src="require('@/assets/articles/sunset.jpg')"
      class="grey darken-4"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
      height="400"
      width="100%"
    >
      <v-row
        class="fill-height pa-2"
        align="center"
      >
        <v-col
          cols="5"
        >
          <v-img
            :src="require('@/assets/GSSLogo.png')"
            class="mr-5"
            contain
            height="90%"
            width="90%"
            max-width="400"
            @click="$vuetify.goTo(0)"
          />
        </v-col>
        <v-col
          cols="7"
        >
          <h1 class="display-3 font-weight-light">
            Gursikh Seva Society
          </h1>

          <div class="subheading text-uppercase pl-2 mb-4">
            Serving the world
          </div>

          <v-btn
            color="primary"
            depressed
          >
            Find out more
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
  </base-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
